import React, { Component } from "react";

import { LiquidMachineDetails } from "./LiquidMachineDetails";

class Liquid extends Component {
  render() {
    return (
      <>
        {LiquidMachineDetails.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                ...style.Container,
                backgroundColor: item.ImageBackgroundColor
              }}
            >
              <div style={style.ImageContainer}>
                <div style={style.Heading}>{item.Heading}</div>
                <div style={style.ImageDiv}>
                  <img alt="liquid" src={item.Image} style={style.Image} />
                </div>
              </div>
              <div style={style.DescriptionContainer}>
                <div style={style.Heading}>Machine Specifications</div>
                <div
                  style={{
                    ...style.SpecificationContainer,
                    backgroundImage: item.DetailsBackgroundColor,
                    color: item.DetailsTextColor
                  }}
                >
                  {item.Details.map((detail, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          ...style.ItemRow,
                          borderBottom: item.DetailsItemBorderColor
                        }}
                      >
                        <div style={style.ItemHeading}>{detail.Heading}</div>
                        <div style={style.ItemValue}>{detail.Value}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default Liquid;

const style = {
  Container: {
    height: "50vh",
    width: "100%",
    display: "flex",
    padding: "20px",
    flexDirection: "row",
    marginBottom: "2vh"
  },
  Heading: {
    height: "45px",
    fontSize: "28px",
    fontWeight: "bold",
    color: "var(--fnp-orange)"
  },
  ImageContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "scroll"
  },
  ImageDiv: {
    display: "flex",
    alignItems: "center",
    justfyContent: "center",
    flexDirection: "column",
    marginTop: "10px"
  },
  DescriptionContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  Image: {
    width: "auto",
    height: "100%",
    maxWidth: "100%",
    borderRadius: "10px",
    backgroundColor: "var(--fnp-image-background)"
  },
  SpecificationContainer: {
    height: "calc(50vh - 85px)",
    overflowY: "scroll",
    width: "100%",
    borderRadius: "10px",
    marginTop: "10px"
  },
  ItemRow: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  ItemHeading: {
    flex: 1,
    fontSize: "14px",
    fontWeight: "bold",
    padding: "7px"
  },
  ItemValue: {
    flex: 3,
    padding: "7px"
  }
};
