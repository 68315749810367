import React, { Component } from "react";

import Footer from "../../Utilities/Footer";
import Header from "../../Utilities/Header";
import HomePageSlider from "../../Utilities/HomePageSlider";
import Content from "./Content";
import Solid from "./Solid";
import Liquid from "./Liquid";

class ProductsPage extends Component {
  render() {
    return (
      <div style={style.Container}>
        <Header />
        <HomePageSlider />
        <Content />
        <div style={style.Heading}>Solid Packing Machines</div>
        <Solid />
        <div style={style.Heading}>Liquid Packing Machines</div>
        <Liquid />
        <Footer />
      </div>
    );
  }
}

export default ProductsPage;

const style = {
  Container: {
    width: "100%"
  },
  Heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    fontSize: "45px",
    fontWeight: "bold",
    width: "100%",
    height: "auto",
    color: "var(--fnp-orange)"
  }
};
