import React, { Component } from "react";

import { Carousel, Image } from "react-bootstrap";

import Slide1 from "../Assets/images/slide-1.jpg";
import Slide2 from "../Assets/images/slide-2.jpg";
import Slide3 from "../Assets/images/slide-3.jpg";
import Slide4 from "../Assets/images/slide-4.jpg";

class HomePageSlider extends Component {
  render() {
    return (
      <div style={style.Container}>
        <Carousel interval={2500}>
          <Carousel.Item>
            <Image
              fluid
              className="d-block w-100"
              src={Slide1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              fluid
              className="d-block w-100"
              src={Slide2}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              fluid
              className="d-block w-100"
              src={Slide3}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              fluid
              className="d-block w-100"
              src={Slide4}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default HomePageSlider;

const style = {
  Container: {
    width: "100%",
    marginTop: "80px"
  }
};
