import LiquidPressNPour from "../../Assets/images/liquid-pressnpour.png";
import LiquidIS1 from "../../Assets/images/liquid-is1.png";
import LiquidIS3PNP1 from "../../Assets/images/liquid-is3pnp-1.png";
import Liquid6DH from "../../Assets/images/liquid-6dh.png";
import LiquidP1 from "../../Assets/images/liquid-p-1.png";
import LiquidPupMini from "../../Assets/images/liquid-pup-mini.png";

export const LiquidMachineDetails = [{
    Heading: "FNP-Liquid-PressNPour",
    Image: LiquidPressNPour,
    ImageBackgroundColor: "var(--fnp-card)",
    DetailsBackgroundColor: "linear-gradient(to bottom,white, var(--fnp-lightgrey))",
    DetailsTextColor: "black",
    DetailsItemBorderColor: "solid 1px var(--fnp-card)",
    Details: [{
        Heading: "Output Quantity",
        Value: "250ml - 1000ml"
      },
      {
        Heading: "Output",
        Value: "1500 ~ 2000 packs/hr"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 5 ml"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "80 mm x 150 mm, 175 mm x 270 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated 65 to 120 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm with 40 mm-690mm"
      },
      {
        Heading: "Input Supply",
        Value: "208/240 V3 phase, 60 Hz, 3 ph + N + G"
      },
      {
        Heading: "Connected Load",
        Value: "5.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "5 KW"
      },
      {
        Heading: "Flow Rate",
        Value: "2000 ltr/hr"
      },
      {
        Heading: "Temperature Range",
        Value: "6° to 10°C maintained"
      },
      {
        Heading: "Minimum Pressure",
        Value: "6 bar"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "1800 mm x 1920 mm 1050 mm"
      },
      {
        Heading: "Weight",
        Value: "700 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Edible Oil, Pasteurised Milk, Pasteurised Fruit Pulp, Cream, Butter Oil & Water."
      }
    ]
  },
  {
    Heading: "FNP-Liquid-IS",
    Image: LiquidIS1,
    ImageBackgroundColor: "white",
    DetailsBackgroundColor: "linear-gradient(to bottom,var(--fnp-blue), var(--fnp-darkblue))",
    DetailsTextColor: "white",
    DetailsItemBorderColor: "solid 1px white",
    Details: [{
        Heading: "Output Quantity",
        Value: "200ml - 1000ml"
      },
      {
        Heading: "Output",
        Value: "800 ~ 1200 packs/hr"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 5 ml"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "100 mm x 135 mm, 170 mm x 265 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated 65 to 120 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm max"
      },
      {
        Heading: "Input Supply",
        Value: "380 V AC, 50 Hz, 3 ph + N + G"
      },
      {
        Heading: "Connected Load",
        Value: "5.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "5 KW"
      },
      {
        Heading: "Flow Rate",
        Value: "150 ltr/hr"
      },
      {
        Heading: "Temperature Range",
        Value: "6° to 10°C maintained"
      },
      {
        Heading: "Minimum Pressure",
        Value: "2 bar"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "735 mm x 640 mm 2760 mm"
      },
      {
        Heading: "Weight",
        Value: "450 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Edible Oil, Ghee, Vanaspati, Pasteurised Fruit Pulp, Mango Pulp, Fresh Cream, Water & Lubriction Oil."
      }
    ]
  },
  {
    Heading: "FNP-Liquid-IS-PNP",
    Image: LiquidIS3PNP1,
    ImageBackgroundColor: "var(--fnp-card)",
    DetailsBackgroundColor: "linear-gradient(to bottom,white, var(--fnp-lightgrey))",
    DetailsTextColor: "black",
    DetailsItemBorderColor: "solid 1px var(--fnp-card)",
    Details: [{
        Heading: "Output Quantity",
        Value: "250ml - 1000ml"
      },
      {
        Heading: "Output",
        Value: "1500 ~ 2000 packs/hr"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 3 ml"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "100 mm x 135 mm, 225 mm x 350 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated 65 to 120 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm max. Width 400mm~690mm"
      },
      {
        Heading: "Input Supply",
        Value: "208/240 V3 phase, 60 Hz, 3 ph + N + G"
      },
      {
        Heading: "Connected Load",
        Value: "5.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "5 KW"
      },
      {
        Heading: "Flow Rate",
        Value: "200 ltr/hr"
      },
      {
        Heading: "Temperature Range",
        Value: "6° to 10°C maintained"
      },
      {
        Heading: "Minimum Pressure",
        Value: "6 bar"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "850 mm x 700 mm 2760 mm"
      },
      {
        Heading: "Weight",
        Value: "700 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Pasteurized milk, cream, edible oil, butter oil, pasteurized fruit pulp and water."
      }
    ]
  },
  {
    Heading: "FNP-Liquid-DH",
    Image: Liquid6DH,
    ImageBackgroundColor: "white",
    DetailsBackgroundColor: "linear-gradient(to bottom,var(--fnp-blue), var(--fnp-darkblue))",
    DetailsTextColor: "white",
    DetailsItemBorderColor: "solid 1px white",
    Details: [{
        Heading: "Output Quantity",
        Value: "100ml - 1000ml"
      },
      {
        Heading: "Output",
        Value: "4800 ~ 9000 packs/hr"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 0.3%"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "100 mm x 130 mm, 170 mm x 265 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated 85 to 120 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm max"
      },
      {
        Heading: "Input Supply",
        Value: "380 V AC, 60 Hz, 3 ph + N + G OR 220/240/480V 50 Hz"
      },
      {
        Heading: "Connected Load",
        Value: "6 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "5 KW"
      },
      {
        Heading: "Flow Rate",
        Value: "150 ltr/hr"
      },
      {
        Heading: "Temperature Range",
        Value: "6° to 10°C maintained"
      },
      {
        Heading: "Minimum Pressure",
        Value: "1 ~ 6 bar"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "1645 mm x 770 mm 3005 mm"
      },
      {
        Heading: "Weight",
        Value: "1200 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Edible Oil, Ghee, Vanaspati, Pasteurised Fruit Pulp, juices, Fresh Cream, Pasteurised Milk, Water & Lubriction Oil."
      }
    ]
  },
  {
    Heading: "FNP-Liquid-P",
    Image: LiquidP1,
    ImageBackgroundColor: "var(--fnp-card)",
    DetailsBackgroundColor: "linear-gradient(to bottom,white, var(--fnp-lightgrey))",
    DetailsTextColor: "black",
    DetailsItemBorderColor: "solid 1px var(--fnp-card)",
    Details: [{
        Heading: "Output Quantity",
        Value: "250ml - 1000ml"
      },
      {
        Heading: "Output",
        Value: "1920 packs/hr"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 3 ml"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "100 mm x 135 mm, 170 mm x 265 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated 65 to 120 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm max"
      },
      {
        Heading: "Input Supply",
        Value: "380 V AC, 50 Hz, 3 ph + N + G"
      },
      {
        Heading: "Connected Load",
        Value: "5.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "5 KW"
      },
      {
        Heading: "Flow Rate",
        Value: "150 ltr/hr"
      },
      {
        Heading: "Temperature Range",
        Value: "6° to 10°C maintained"
      },
      {
        Heading: "Minimum Pressure",
        Value: "2 bar"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "735 mm x 640 mm 2760 mm"
      },
      {
        Heading: "Weight",
        Value: "450 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Edible Oil, Ghee, Vanaspati, Pasteurised Fruit Pulp, Mango Pulp, Fresh Cream, Water & Lubriction Oil."
      }
    ]
  },
  {
    Heading: "FNP-Liquid-MINI-PUP",
    Image: LiquidPupMini,
    ImageBackgroundColor: "white",
    DetailsBackgroundColor: "linear-gradient(to bottom,var(--fnp-blue), var(--fnp-darkblue))",
    DetailsTextColor: "white",
    DetailsItemBorderColor: "solid 1px white",
    Details: [{
        Heading: "Output Quantity",
        Value: "250ml – 500ml or 500ml – 1000ml"
      },
      {
        Heading: "Output",
        Value: "700-900 packs/hr for liquid or 500-600 packs/hr for viscous liquids"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 5 ml"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "110 mm x 170 mm, 180 mm x 260 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated 100 to 140 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm max"
      },
      {
        Heading: "Input Supply",
        Value: "380 V AC, 60 Hz, 3 ph + N + G OR 220/240/480V 50 Hz"
      },
      {
        Heading: "Connected Load",
        Value: "6.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "6 KW"
      },
      {
        Heading: "Flow Rate",
        Value: "150 ltr/hr"
      },
      {
        Heading: "Temperature Range",
        Value: "6° to 10°C maintained"
      },
      {
        Heading: "Minimum Pressure",
        Value: "2 bar"
      },
      {
        Heading: "Air Pressure",
        Value: "6 bar constant dry air"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "870 mm x 915 mm 1800 mm"
      },
      {
        Heading: "Weight",
        Value: "350 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Glue, Milk, Edible Oil, Butter Oil, Shampoo, Pasteurised Fruit Pulp, Mango Pulp, Fresh Cream, Chees, Yougurt, Water, ketchup, Mayonnaise, Lubriction Oil, as well as all kind of semi-viscous & liquid product."
      }
    ]
  }
]