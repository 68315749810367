import React, { Component } from "react";

import { MDBIcon } from "mdbreact";
import Recaptcha from "react-recaptcha";
import { Button, InputGroup, FormControl } from "react-bootstrap";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      comment: "",
      subject: "",
      disableSubmitButton: true
    };
  }

  handleActivateSubmitButton = () => {
    this.setState({
      disableSubmitButton: false
    });
  };

  handleSubjectSelection = e => {
    this.setState({
      subject: e.target.value
    });
  };

  handleNameChange = e => {
    this.setState({
      name: e.target.value
    });
  };

  handleEmailChange = e => {
    this.setState({
      email: e.target.value
    });
  };

  handleCommentChange = e => {
    this.setState({
      comment: e.target.value
    });
  };

  validateEmail = email => {
    var re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  };

  handleSendEmail = () => {};

  render() {
    let { name, email, comment, subject, disableSubmitButton } = this.state;

    disableSubmitButton =
      name === "" ||
      subject === "" ||
      comment === "" ||
      disableSubmitButton ||
      !this.validateEmail(email);

    return (
      <div style={style.Container}>
        <div style={style.MainHeading}>"We would love to hear from you!"</div>
        <div style={style.Row}>
          <div style={style.Col}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1699.9769656645058!2d74.40376059916304!3d31.552878895807222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919054dacf294ff%3A0xe2c7154a3b5ad819!2sFillNPack!5e0!3m2!1sen!2s!4v1557657669200!5m2!1sen!2s"
              frameBorder={0}
              title="FillNPack"
              style={style.Map}
              allowFullScreen
            />
          </div>
          <div style={style.HideCol}>
            <div style={style.Heading}>Contact Us</div>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <MDBIcon icon="signature" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Name"
                aria-label="Name"
                aria-describedby="basic-addon1"
                onChange={() => this.handleNameChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <MDBIcon icon="at" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                onChange={() => this.handleEmailChange}
              />
            </InputGroup>
            <select
              name="Subject"
              value={subject}
              style={style.SubjectSelect}
              onChange={this.handleSubjectSelection}
            >
              <option value="" disabled>
                Select Subject
              </option>
              <option value="Partnership">Partnership</option>
              <option value="General Inquiry">General Inquiry</option>
              <option value="Business Proposition">Business Proposition</option>
            </select>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <MDBIcon far icon="comment" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="textarea"
                aria-label="With textarea"
                onChange={() => this.handleCommentChange}
              />
            </InputGroup>
            <Recaptcha
              sitekey="6Ld0EaMUAAAAAAC04sYHYi8-4oxRIDGIY_947S3k"
              onloadCallback={this.handleActivateSubmitButton}
            />
            <Button
              type="submit"
              disabled={disableSubmitButton}
              onClick={this.handleSendEmail}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;

export const style = {
  Map: {
    border: 0,
    width: "100%",
    height: "40vh"
  },
  Container: {
    display: "flex",
    paddingBottom: "10vh",
    flexDirection: "column",
    color: "var(--fnp-grey)"
  },
  Row: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  Col: {
    flex: 1,
    display: "flex",
    padding: "20px",
    flexDirection: "column"
  },
  HideCol: {
    display: "none"
  },
  MainHeading: {
    padding: "20px",
    fontSize: "34px",
    fontWeight: "bold",
    color: "var(--fnp-orange)"
  },
  Heading: {
    fontSize: "28px",
    fontWeight: "bold",
    padding: "0px 20px",
    color: "var(--fnp-blue)"
  },
  Divider: {
    opacity: 0.5,
    height: "5px",
    width: "auto",
    display: "block",
    overflow: "hidden",
    margin: "10px 40px",
    borderRadius: "3px",
    backgroundColor: "var(--fnp-grey)"
  },
  SubjectSelect: {}
};
