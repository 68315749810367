import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  BrowserRouter as Router
} from "react-router-dom";

import "./App.scss";
import MainPage from "./Pages/MainPage";
import AboutPage from "./Pages/AboutPage";
import UnknownPage from "./Pages/UnknownPage";
import ContactPage from "./Pages/ContactPage";
import ProductsPage from "./Pages/ProductsPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/home.html" />}
          />
          <Route path="/home.html" component={MainPage} />
          <Route path="/products.html" component={ProductsPage} />
          <Route path="/about.html" component={AboutPage} />
          <Route path="/contact.html" component={ContactPage} />
          <Route path="/not-found" component={UnknownPage} />
          <Route path="*" component={() => <Redirect to={`/not-found`} />} />
        </Switch>
      </Router>
    </div>
  );
}

export default withRouter(App);
