import React, { Component } from "react";

import { MDBIcon } from "mdbreact";

import FnPLogo from "../Assets/images/footer_logo.jpg";

class Footer extends Component {
  handleFBClick = () => {
    window.open("https://www.facebook.com/FillNpack/");
  };

  handleYoutubeClick = () => {
    window.open("https://www.youtube.com/channel/UCtcTc0IQIFXdE31KCZ2dBWA");
  };

  render() {
    return (
      <div style={style.Container}>
        <div style={style.DetailsRow}>
          <div style={style.InfoContainer}>
            <div style={style.InfoHeading}>Contact Information</div>
            <div style={style.Info}>
              <div style={style.Label}>Address:</div>
              <div style={style.Value}>
                Plot # 53-A, Mian Ameer-u-din Park,
                <br />
                Aqsa Chowk Gaziabad Lahore Pakistan
              </div>
            </div>
            <div style={style.Info}>
              <div style={style.Label}>Mobile:</div>
              <div style={style.Value}>+92 300 4467832</div>
            </div>
            <div style={style.Info}>
              <div style={style.Label}>Telephone:</div>
              <div style={style.Value}>+92 42 36630077</div>
            </div>
            <div style={style.Info}>
              <div style={style.Label}>Email:</div>
              <div style={style.Value}>
                <a href="mailto:sajid@fillnpack.com.pk">
                  sajid@fillnpack.com.pk
                </a>
              </div>
            </div>
            <div style={style.Info}>
              <div style={style.LabelCenter}>Social Media:</div>
              <div style={style.Value}>
                <MDBIcon
                  fab
                  size="2x"
                  target="_blank"
                  icon="facebook-square"
                  style={style.Facebook}
                  onClick={this.handleFBClick}
                />
                <MDBIcon
                  fab
                  size="2x"
                  icon="youtube"
                  target="_blank"
                  style={style.Youtube}
                  onClick={this.handleYoutubeClick}
                />
              </div>
            </div>
          </div>
          <div style={style.NavbarDetailContainer}>
            <div style={style.InfoHeading}>Quick Navigation</div>
            <a style={style.NavShortcuts} href="/home.html">
              Home
            </a>
            <a style={style.NavShortcuts} href="/products.html">
              Products
            </a>
            <a style={style.NavShortcuts} href="/about.html">
              About
            </a>
            <a style={style.NavShortcuts} href="/contact.html">
              Contact
            </a>
          </div>
        </div>
        <div style={style.LogoRow}>
          <img alt="fnp-logo" src={FnPLogo} />
          <div style={style.CopyRightsText}>
            CopyRights &copy; 2019 fillnpack.com.pk
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;

const style = {
  Container: {
    width: "100%",
    color: "white",
    height: "30vh",
    display: "flex",
    padding: "20px",
    flexDirection: "column",
    backgroundColor: "var(--fnp-footer)"
  },
  InfoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  NavbarDetailContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  NavShortcuts: {
    color: "white",
    fontSize: "14px",
    cursor: "pointer",
    textDecoration: "underline"
  },
  Row: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  LogoRow: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center"
  },
  CopyRightsText: {
    fontSize: "12px",
    padding: "10px"
  },
  Youtube: {
    width: "30px",
    height: "30px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    color: "var(--fnp-youtube)"
  },
  Facebook: {
    width: "30px",
    height: "30px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    color: "var(--fnp-facebook)"
  },
  Info: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "2px"
  },
  Label: {
    flex: 1,
    fontSize: "12px",
    fontWeight: "bold",
    paddingRight: "5px",
    alignItems: "center"
  },
  LabelCenter: {
    flex: 1,
    display: "flex",
    fontSize: "12px",
    fontWeight: "bold",
    paddingRight: "5px",
    alignItems: "center"
  },
  Value: {
    flex: 3,
    display: "flex",
    fontSize: "11px",
    alignItems: "center"
  },
  InfoHeading: {
    fontSize: "14px",
    fontWeight: "bold",
    paddingBottom: "10px"
  },
  DetailsRow: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  }
};
