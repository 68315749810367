import SolidAWImage from "../../Assets/images/solid-aw.png";
import SolidBulk from "../../Assets/images/solid-bk.png";
import SolidDV5 from "../../Assets/images/solid-dv5.png";
import SolidMH from "../../Assets/images/solid-mh.png";

export const SolidMachineDetails = [{
    Heading: "FNP-Solid-Auger",
    Image: SolidAWImage,
    ImageBackgroundColor: "var(--fnp-card)",
    DetailsBackgroundColor: "linear-gradient(to bottom,white, var(--fnp-lightgrey))",
    DetailsTextColor: "black",
    DetailsItemBorderColor: "solid 1px var(--fnp-card)",
    Details: [{
        Heading: "Output Quantity",
        Value: "50g - 2000g"
      },
      {
        Heading: "Output",
        Value: "20 – 55 bags/min"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 5 gm"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "120 mm x 150 mm, 355 mm x 500 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated, Metalize, Aluminum Foil 60 to 140 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm max"
      },
      {
        Heading: "Input Supply",
        Value: "380 V AC, 50 Hz, 3 ph + N + G"
      },
      {
        Heading: "Connected Load",
        Value: "5.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "5 KW"
      },
      {
        Heading: "Air Pressure",
        Value: "6 bar Constant dry air"
      },
      {
        Heading: "Air consumption",
        Value: "800 ltr/mnt"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "1110 mm x 1700 mm 2400 mm, (1300 mm x 1956 mm x 3350 mm) for 5kg"
      },
      {
        Heading: "Weight",
        Value: "600 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Potato Chips, Snacks ,Rice, Macaronis Beans, Pasta, Dry Fruits, Vermicelli, Candies & Confectionery products as well as all kinds of light weight products."
      }
    ]
  },
  {
    Heading: "FNP-Solid-Volumetric",
    Image: SolidDV5,
    ImageBackgroundColor: "white",
    DetailsBackgroundColor: "linear-gradient(to bottom,var(--fnp-blue), var(--fnp-darkblue))",
    DetailsTextColor: "white",
    DetailsItemBorderColor: "solid 1px white",
    Details: [{
        Heading: "Output Quantity",
        Value: "1000g – 5000g"
      },
      {
        Heading: "Output",
        Value: "12 – 40 packs/min"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 5 gm"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "120 mm x 150 mm, 355 mm x 500 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated, Metalize, Aluminum Foil 60 to 140 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm max"
      },
      {
        Heading: "Input Supply",
        Value: "380 V AC, 50 Hz, 3 ph + N + G"
      },
      {
        Heading: "Connected Load",
        Value: "5.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "5 KW"
      },
      {
        Heading: "Air Pressure",
        Value: "6 bar Constant dry air"
      },
      {
        Heading: "Air consumption",
        Value: "800 ltr/mnt"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "1110 mm x 1700 mm 2400 mm, (1300 mm x 1956 mm x 3350 mm) for 5kg"
      },
      {
        Heading: "Weight",
        Value: "600 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Potato Chips, Snacks ,Rice, Macaronis Beans, Pasta, Dry Fruits, Vermicelli, Candies & Confectionery products as well as all kinds of light weight products."
      }
    ]
  },
  {
    Heading: "FNP-Solid-Bulk",
    Image: SolidBulk,
    ImageBackgroundColor: "var(--fnp-card)",
    DetailsBackgroundColor: "linear-gradient(to bottom,var(--fnp-blue), var(--fnp-darkblue))",
    DetailsTextColor: "black",
    DetailsItemBorderColor: "solid 1px var(--fnp-card)",
    Details: [{
        Heading: "Output Quantity",
        Value: "5kg - 20kg, 10kg - 50kg"
      },
      {
        Heading: "Output",
        Value: "10 - 15 bags/min, 06 - 12 bags/min"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 0.05% - 0.25%"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "305 mm x 356 mm, 533 mm x 890 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated, Metalize, Aluminum Foil, Polypropylene woven bag, Jute bag, Cotton Bag, Paper Bag, China fusing, etc"
      },
      {
        Heading: "Input Supply",
        Value: "380 V AC, 50 Hz, 3 ph + N + G"
      },
      {
        Heading: "Connected Load",
        Value: "2.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "2.35 KW"
      },
      {
        Heading: "Air Pressure",
        Value: "6 bar Constant dry air"
      },
      {
        Heading: "Air consumption",
        Value: "800 ltr/mnt"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "3550 mm x 1525 mm 2970 mm(Depend on customized requirements.)"
      },
      {
        Heading: "Weight",
        Value: "600 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Potato Chips, Snacks ,Rice, Macaronis Beans, Pasta, Dry Fruits, Vermicelli, Candies & Confectionery products as well as all kinds of light weight products."
      }
    ]
  },
  {
    Heading: "FNP-Solid-MH",
    Image: SolidMH,
    ImageBackgroundColor: "white",
    DetailsBackgroundColor: "linear-gradient(to bottom,var(--fnp-blue), var(--fnp-darkblue))",
    DetailsTextColor: "white",
    DetailsItemBorderColor: "solid 1px white",
    Details: [{
        Heading: "Output Quantity",
        Value: "15g - 1000g"
      },
      {
        Heading: "Output",
        Value: "30 - 85 packs/min"
      },
      {
        Heading: "Filler Accuracy",
        Value: "± 5gm - 5g"
      },
      {
        Heading: "Pack / Quantity Range",
        Value: "120 mm x 150 mm, 225 mm x 350 mm"
      },
      {
        Heading: "Packaging Material",
        Value: "Co-extruded 3 or 5 Layer PE Based or Laminated, Metalize, Aluminum Foil 40 to 120 microns"
      },
      {
        Heading: "Film Reel",
        Value: "Core dia 76 mm & Reel diameter 300 mm max"
      },
      {
        Heading: "Input Supply",
        Value: "220 or 380V AC, 50 Hz, 3 ph + N + G"
      },
      {
        Heading: "Connected Load",
        Value: "3.5 KVA"
      },
      {
        Heading: "Peak Power Rating",
        Value: "3 KW"
      },
      {
        Heading: "Air Pressure",
        Value: "6 bar Constant dry air"
      },
      {
        Heading: "Air consumption",
        Value: "800 ltr/mnt"
      },
      {
        Heading: "Machine Dimensions along with conveyor (W x D x H) approx.",
        Value: "1110 mm x 1700 mm 2438 mm"
      },
      {
        Heading: "Weight",
        Value: "700 kgs (Net)"
      },
      {
        Heading: "Suitable for Packing",
        Value: "Potato Chips, Snacks ,Rice, Macaronis Beans, Pasta, Dry Fruits, Vermicelli, Candies & Confectionery products as well as all kinds of light weight products."
      }
    ]
  }
]