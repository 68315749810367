import React, { Component } from "react";

import Footer from "../../Utilities/Footer";
import Header from "../../Utilities/Header";
import HomePageSlider from "../../Utilities/HomePageSlider";
import Content from "./Content";

class MainPage extends Component {
  render() {
    return (
      <div style={style.Container}>
        <Header />
        <HomePageSlider />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default MainPage;

const style = {
  Container: {
    width: "100%",
    overflowY: "auto"
  }
};
