import React, { Component } from "react";

class Content extends Component {
  render() {
    return (
      <div style={style.Container}>
        <div style={style.MainHeading}>Company Overview</div>
        <div style={style.Heading}>
          "We take pride in providing our clients with various solutions on how
          to package and invest in their products that would provide them with
          optimum results."
        </div>
        <div style={style.Row}>
          <div style={style.Col}>
            There are many factors apart from quality on which the success of
            product depends. Packaging is among one of them. FillnPack has set a
            new benchmark in the arena of packaging machines. We are a renowned
            manufacturer, supplier and exporter of Fully Automatic Form Fill &
            Seal Machines to pack free flowing liquids, viscous products,
            powders, granules, etc. in pillow type flexible laminated, poly and
            heat sealable films.
          </div>
          <div style={style.Col}>
            Our products are based on "PLC Technology" (Programmable Logic
            Control Systems), the micro process digital technology which gives
            optimum accuracy and high reliable services, minimizes the
            maintenance time, as the defect data is shown on the display screen
            provided. Since its incorporation in the year 2005, FillnPack has
            accomplished an installation base of over 50 machines within
            Pakistan.
          </div>
        </div>
        <div style={style.Divider} />
        <div style={style.Row}>
          <div style={style.Col}>
            Our products include Packing & Wrapping Machines, Liquid Packaging
            Machines, Pouch Packaging Machines, Sachet Packing Machines, etc. We
            follow harsh and rigid quality control measures at every stage of
            our process that facilitate smooth and efficient production from
            every stage.
          </div>
          <div style={style.Col} />
        </div>
      </div>
    );
  }
}

export default Content;

export const style = {
  Container: {
    display: "flex",
    paddingBottom: "10vh",
    flexDirection: "column",
    color: "var(--fnp-grey)"
  },
  Row: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  Col: {
    flex: 1,
    display: "flex",
    padding: "20px",
    flexDirection: "column"
  },
  MainHeading: {
    padding: "20px",
    fontSize: "34px",
    fontWeight: "bold",
    color: "var(--fnp-orange)"
  },
  Heading: {
    fontSize: "28px",
    fontWeight: "bold",
    padding: "0px 20px",
    color: "var(--fnp-blue)"
  },
  Divider: {
    opacity: 0.5,
    height: "5px",
    width: "auto",
    display: "block",
    overflow: "hidden",
    margin: "10px 40px",
    borderRadius: "3px",
    backgroundColor: "var(--fnp-grey)"
  }
};
