import React, { Component } from "react";

class Content extends Component {
  render() {
    return (
      <div style={style.Container}>
        <div style={style.Heading}>Welcome</div>
        <div style={style.Row}>
          <div style={style.Col}>
            We at FillnPack are dedicated to offer most sought after features in
            packaging machines. FillnPack defines new levels of competence very
            often, to offer world class systems in fill and pack, backed by
            strong & wide after sales-service network. We are engaged in
            manufacturing of rice filling machines, Milk, Cream, Paste,
            Vegetable Oil & ghee etc. These machines are environmentally
            friendly, ensuring pollution free and are prone to less wastage
            giving an accuracy of +/- 0.5%.
          </div>
          <div style={style.Col}>
            Our keen knowledge of the packaging industry has helped us to
            develop solutions that answer the need of today’s clients. These
            solutions allow you to:
            <li>Have Filling accuracy</li>
            <li>Reduce Operating Cost</li>
            <li>Increased Productivity</li>
            <li>Occupies less floor area</li>
            <li>Excellent sealing quality</li>
            <li>Controllable packing speed</li>
            <li>Increase Customer Satisfaction</li>
            <li>Easy operation Low power consumption</li>
            In this way FillnPack is incorporating indigenous technology and
            innovative skills. So, the company is all set to meet the customer
            requirements.
          </div>
        </div>
        <div style={style.Divider} />
        <div style={style.Heading}>FILLNPACK Packaging Systems</div>
        <div style={style.Row}>
          <div style={style.Col}>
            There are many factors apart from quality on which the success of
            product depends. Packaging is among one of them. FillnPack has set a
            new benchmark in the arena of packaging machines. We are a renowned
            manufacturer, supplier and exporter of Fully Automatic Form Fill &
            Seal Machines to pack free flowing liquids, viscous products,
            powders, granules, etc. in pillow type flexible laminated, poly and
            heat sealable films.
          </div>
          <div style={style.Col}>
            FillNPack Packaging System are:
            <li>affordable.</li>
            <li>contain no proprietary parts.</li>
            <li>
              backed by 15 years of combined experience in v/f/f/s applications.
            </li>
            <li>
              designed to be simple where possible and sophisticated where
              appropriate.
            </li>
            <li>
              engineered to optimize performance and minimize down time, give
              away, and the pay back time of your investment.
            </li>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;

export const style = {
  Container: {
    display: "flex",
    paddingBottom: "10vh",
    flexDirection: "column",
    color: "var(--fnp-grey)"
  },
  Row: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  Col: {
    flex: 1,
    display: "flex",
    padding: "20px",
    flexDirection: "column"
  },
  Heading: {
    padding: "20px",
    fontSize: "28px",
    fontWeight: "bold",
    color: "var(--fnp-orange)"
  },
  Divider: {
    opacity: 0.5,
    height: "5px",
    width: "auto",
    display: "block",
    overflow: "hidden",
    margin: "10px 40px",
    borderRadius: "3px",
    backgroundColor: "var(--fnp-grey)"
  }
};
