import React, { Component } from "react";
import { Nav, Navbar } from "react-bootstrap";
import FnPLogo from "../Assets/images/footer_logo.jpg";

class Header extends Component {
  render() {
    return (
      <div style={style.Container}>
        <Navbar>
          <Navbar.Brand href="/home.html">
            <img alt="fillnpack-logo" src={FnPLogo} />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="right-aligned"
              defaultActiveKey="/home"
              style={style.TabLinksContainer}
            >
              <Nav.Item>
                <Nav.Link href="/home.html" style={style.TabLinks}>
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/products.html" style={style.TabLinks}>
                  Products
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/about.html" style={style.TabLinks}>
                  About
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/contact.html" style={style.TabLinks}>
                  Contact
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;

const style = {
  Container: {
    top: "0px",
    zIndex: 100,
    opacity: 0.9,
    right: "0px",
    width: "100%",
    height: "80px",
    position: "fixed",
    boxShadow: "0px 0px 10px 4px black",
    backgroundColor: "var(--fnp-header)"
  },
  TabLinksContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  TabLinks: {
    color: "white",
    fontSize: "16px",
    fontWeight: "bold"
  }
};
