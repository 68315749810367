import React, { Component } from "react";

class Content extends Component {
  render() {
    return (
      <div style={style.Container}>
        <div style={style.Heading}>Products</div>
        <div style={style.Row}>
          <div style={style.Col}>
            FillnPack is a trust worthy manufacturer, exporter and supplier of
            superlative packaging machines such as:
            <li>Sachet Packing Machines</li>
            <li>Automatic Seal Machines</li>
            <li>Pouch Packaging Machines</li>
            <li>Liquid Packaging Machines</li>
            <li>Packing & Wrapping Machines</li>
            <li>Automatic Form Fill Machines</li>
            <li>Packaging Machines & Customized Parts</li>
            We are a quality driven company working with the motto of providing
            latest innovative packaging solutions at reasonable prices to our
            business associates. Every operation is executed under the strict
            supervision of technically trained engineers that hardly leaves even
            a marginal possibility of quality degradation.
            <br />
            The finished packing & wrapping machines, liquid packaging machines,
            pouch packaging machines, sachet packing machines, etc. are properly
            tested and inspected for their performance prior to packaging, thus
            ensuring the flow of only best items to the market.
          </div>
          <div style={style.Col}>
            <iframe
              style={style.YoutubeVidePlaylist}
              src="https://www.youtube.com/embed/videoseries?list=PLKXAN_HzGtD1sMZ4YG86bBL0IX0oKbEMO"
              frameBorder={0}
              allowFullScreen
              title="Youtube Playlist"
            />
          </div>
        </div>
        <div style={style.Divider} />
        <div style={style.Heading}>Mission Statement</div>
        <div style={style.Row}>
          <div style={style.Col}>
            FILLNPACK is passionately committed to creating value-added
            packaging solutions that empower our customers to grow, innovate and
            succeed. We pride ourselves on setting the industry standard in
            technology, efficiency and reliability in the engineering of our
            products. With the most experienced team in the industry, we will
            help you develop the perfect package to suit your product, your
            brand and your objectives for profitability. This commitment begins
            with the first consultation and continues with comprehensive
            worldwide service and support for as long as you own your FILLNPACK.
          </div>
          <div style={style.Col}>
            <div
              className="fb-page"
              data-href="https://www.facebook.com/FillNpack/?fref=ts"
              data-tabs="timeline"
              data-width={500}
              data-height={300}
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="false"
            >
              <blockquote
                cite="https://www.facebook.com/FillNpack/?fref=ts"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/FillNpack/?fref=ts">
                  Fillnpack
                </a>
              </blockquote>
            </div>
          </div>
        </div>
        <div style={style.Divider} />
      </div>
    );
  }
}

export default Content;

export const style = {
  Container: {
    display: "flex",
    paddingBottom: "2vh",
    flexDirection: "column",
    color: "var(--fnp-grey)"
  },
  YoutubeVidePlaylist: {
    width: "100%",
    height: "40vh"
  },
  FacebookTimeline: {
    width: "100%",
    height: "40vh"
  },
  Row: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  Col: {
    flex: 1,
    display: "flex",
    padding: "20px",
    flexDirection: "column"
  },
  Heading: {
    padding: "20px",
    fontSize: "28px",
    fontWeight: "bold",
    color: "var(--fnp-orange)"
  },
  Divider: {
    opacity: 0.5,
    height: "5px",
    width: "auto",
    display: "block",
    overflow: "hidden",
    margin: "10px 40px",
    borderRadius: "3px",
    backgroundColor: "var(--fnp-grey)"
  }
};
